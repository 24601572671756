<template>
    <div style="height:100vh">
        <div>
            <div style="margin: 7% 33%;padding:0px 6%;background-color: white;border-radius: 25px">
                <div style="width: 80%;margin-left: 10%;float: left;margin-bottom: 5px">
                    <img class="signIn_logo" src="../assets/logo.png"/>
                </div>
                <span style="font-size:1.25rem;font-weight:600; text-align:center;">Welcome to join us !</span>

                <!--  <template>
                      <Time :time="time3" :interval="1" />
                  </template>-->
                <Form style="margin-top: 20px">
                    <FormItem prop="user">
                        <Input type="text" v-model="user.name" placeholder="Name">
                            <Icon type="ios-person-outline" slot="prepend"></Icon>
                        </Input>
                    </FormItem>
                    <FormItem prop="email">
                        <Input type="text" v-model="user.email" placeholder="Email">
                            <Icon type="ios-mail-outline" slot="prepend"/>
                        </Input>
                    </FormItem>
                    <FormItem prop="password">
                        <Input type="password" v-model="user.password" placeholder="Password">
                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                        </Input>
                    </FormItem>
                    <FormItem prop="password">
                        <p class="signIn_tip">Confirm your password.</p>
                        <Input type="password" placeholder="Password" v-model="user.password_r">
                            <Icon type="ios-lock-outline" slot="prepend"></Icon>
                        </Input>
                    </FormItem>
                    <FormItem>
                        <div style="width:60%;float: left">
                            <Input type="text" v-model="captchaValue" placeholder="Captcha">
                                <Icon type="logo-reddit" slot="prepend"></Icon>
                            </Input>
                        </div>
                        <div style="width:40%;float: left">
                            <img style="margin-left:3%;width: 97%;height: 100%" :src="captchaPic" @click="getCaptcha"/>
                        </div>
                    </FormItem>
                    <FormItem prop="password">
                        <p class="signIn_tip">We will send you a verification code to your email.</p>
                        <div style="width:35%;float: left">
                            <Button long @click="send">
                                {{send_tip}}
                            </Button>
                        </div>
                        <div style="margin-left:5%;width:60%;float: left">
                            <Input type="text" v-model="user.code" placeholder="Please input your code">
                            </Input>
                        </div>
                    </FormItem>
                    <FormItem>
                        <Button long type="primary" style="" @click="submitCheck">OK</Button>
                    </FormItem>
                </Form>
                <a @click="gotoLog">If you already have an account, click here.</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "signIn",
        data() {
            return {
                captchaPic: '',
                captchaKey: '',
                captchaValue: '',
                error: false,
                send_tip: "Send",
                total_time: 0,
                user: {
                    name: '',
                    email: '',
                    password: '',
                    password_r: '',
                    code: ''
                },
                time3: new Date()
            }
        },
        computed: {},
        methods: {
            getCaptcha() {
                this.$axios({
                        method: 'get',
                        url: "/captcha/getCaptchaImage?p=" + Math.random(),
                    }
                ).then((response) => {
                    this.captchaPic = "data:image/png;base64," + response.data.data.image;
                    this.captchaKey = response.data.data.key
                })
            },
            submitCheck() {
                var Tself = this;
                if (this.user.name === "") {
                    this.error = 'Empty email';
                    this.$Message.warning('Empty name!');
                    return;
                }
                if (this.user.email === "") {
                    this.error = 'Empty email';
                    this.$Message.warning('You should check your email address.');
                    return;
                }
                if (this.user.password === "") {
                    this.$Message.warning("Empty password!")
                    return;
                }
                if(this.user.password_r !== this.user.password){
                    this.$Message.warning("Inconsistent passwords!")
                    return;
                }
                if (this.captchaValue === "") {
                    this.$Message.warning("Empty captcha!")
                    return;
                }
                // 检查验证码
                this.$axios({
                        method: 'post',
                        url: "/captcha/checkCaptcha?code=" + this.captchaValue + "&key=" + this.captchaKey,
                    }
                ).then((response) => {
                    let result = response.data.data.item
                    if (result === "false") {
                        this.$Message.error("Wrong captcha!")
                        this.getCaptcha()
                    }
                    if (result === "true") {
                        this.register()
                    }
                })
            },
            gotoLog() {
                this.$router.push("/Login");
            },
            countDown() {
                let clock = window.setInterval(() => {
                    this.total_time--;
                    this.send_tip = this.total_time + 's';
                    if (this.total_time === 0 || this.total_time < 0) {
                        this.send_tip = "Send";
                        clearInterval(clock);
                    }
                }, 1000)
            },
            getCode() {
                return "Code"
            },
            send() {
                /*                if(this.user.email === ''){
                                    this.$Message.warning('You should check your email address.');
                                }
                               else */
                if (this.send_tip === 'Send') {
                    if (this.user.email !== '') {
                        this.$axios.post(
                            "/user/getVertificationCode",
                            this.user.email,
                            {
                                headers: {
                                    'Content-Type': 'text/xml'
                                }
                            }
                        ).then((response) => {
                            //成功登陆
                            this.total_time = 60;
                            console.log(response);
                            this.$Message.success('The mail have send successfully.');
                            this.countDown();
                        }).catch((error) => {
                            console.log(error);
                            this.$Message.error(error.message);
                        })

                    } else {
                        this.$Message.warning('The mail shouldn\'t be empty.');
                    }
                }
            },
            register() {
                if (this.user.email === "") {
                    this.error = '邮箱不得为空';
                    this.$Message.warning('You should check your email address.');
                } else {
                    this.$axios.post(
                        "/user/register",
                        {
                            code: this.user.code,
                            mailAddress: this.user.email,
                            password: this.user.password,
                            username: this.user.name
                        }
                    ).then((response) => {
                        //成功登陆
                        console.log(response);
                        if (response.data.data.items === "Verification code not sent") {
                            this.$Message.info(response.data.data.items);
                        } else if (response.data.data.items === "Verification code error") {
                            this.$Message.info(response.data.data.items);
                        } else if (response.data.data.items === "Email has been registered") {
                            this.$Message.info(response.data.data.items);
                            this.$router.push("/login");
                        } else {
                            this.$router.push("/login");
                            this.$Message.success("Success!");
                        }
                    }).catch((error) => {
                        this.$Message.error(error.message);
                    })
                }
            }

        },
        mounted() {
            this.getCaptcha()
        }
    }
</script>

<style scoped>
    .signIn_tip {
        text-align: left;
        font-size: 0.75rem;
        color: darkgray;
    }

    .signIn_logo {
        height: 25%;
        width: 100%;
    }
</style>
